* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

::-moz-selection {
  background: #ff9800;
  color: #fff;
}

::selection {
  background: #ff9800;
  color: #fff;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font: inherit;
  vertical-align: baseline;
  line-height: normal;
}

html {
  font-size: 62.5%;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Yantramanav', 'fontello', monospace;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #2e2c2d;
  text-rendering: optimizelegibility;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

a {
  text-decoration: none;
  border: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

strong,
b {
  font-weight: 700;
}

em {
  font-style: italic;
}

button,
input[type="submit"] {
  cursor: pointer;
  border: 0;
  appearance: none;
}

input,
textarea,
select {
  font-family: 'Yantramanav', monospace;
  font-size: 16px;
}

input.incorrect,
textarea.incorrect,
select.incorrect {
  border-color: #db4437;
}

input.correct,
textarea.correct,
select.correct {
  border-color: #009688;
}

input[type="search"] {
  appearance: none;
}

textarea {
  resize: vertical;
}

iframe {
  position: relative;
}

.header,
.main,
.footer {
  width: 100%;
  display: block;
  clear: both;
}

.vsc-controller {
  display: none !important;
}

.form-control,
.custom-file+label {
  width: 100%;
  height: 40px;
  display: block;
  padding: 0 15px;
  line-height: 39px;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  color: #2e2c2d;
  border-radius: 40px;
  border: 1px solid #818286;
  background: #fff;
}

.form-control::-webkit-input-placeholder,
.custom-file+label::-webkit-input-placeholder {
  color: #818286;
  opacity: 1;
}

.form-control:-moz-placeholder,
.custom-file+label:-moz-placeholder {
  color: #818286;
  opacity: 1;
}

.form-control::-moz-placeholder,
.custom-file+label::-moz-placeholder {
  color: #818286;
  opacity: 1;
}

.form-control:-ms-input-placeholder,
.custom-file+label:-ms-input-placeholder {
  color: #818286;
  opacity: 1;
}

textarea.form-control {
  padding: 15px;
  height: auto;
  line-height: normal;
  border-radius: 20px;
}

.custom-file {
  position: absolute;
  left: -10000px;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}

.custom-file.incorrect+label {
  border-color: red;
}

.custom-file.correct+label {
  border-color: green;
}

.custom-file+label {
  cursor: pointer;
  overflow: hidden;
}

.custom-file+label:after {
  content: "Anexar";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0 30px;
  font-weight: 700;
  color: #fff;
  background: #3f51b5;
  border-radius: 20px;
  font-style: normal;
  text-transform: uppercase;
  margin-right: 10px;
}

.custom-check {
  position: absolute;
  left: -10000px;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}

.custom-check+label {
  position: relative;
  padding-left: 25px;
  vertical-align: middle;
  cursor: pointer;
}

.custom-check+label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 18px;
  height: 18px;
  transform: translateY(-50%);
  display: block;
  border: 1px solid #673ab7;
}

.custom-check:checked+label:before {
  background: #673ab7;
  box-shadow: 0px 0px 0px 2px #fff inset;
}

input[type="radio"].custom-check+label:before {
  border-radius: 100%;
}

input[type="checkbox"].custom-check+label:before {
  border-radius: 5px;
}

.input-group {
  position: relative;
  border-radius: 40px;
  border: 1px solid #fff;
}

.input-group .form-control,
.input-group .custom-file+label {
  border: none;
  border-radius: 0;
}

.input-group .input-addon {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  padding: 10px;
  background: #e6e6e6;
  text-align: center;
  color: #2e2c2d;
}

.input-group.addon-left .input-addon {
  left: 0;
  right: auto;
  border-radius: 40px;
}

.input-group.addon-left .form-control,
.input-group.addon-left .custom-file+label {
  padding-left: 55px;
}

.input-group.addon-right .input-addon {
  left: auto;
  right: 0;
  border-radius: 40px;
}

.input-group.addon-right .form-control,
.input-group.addon-right .custom-file+label {
  padding-right: 55px;
}

.input-group-filter {
  position: relative;
  border-radius: 40px;
}

.input-group-filter .form-control,
.input-group-filter .custom-file+label {
  appearance: none;
  padding-right: 45px;
  font-style: normal;
}

.input-group-filter .filter-addon {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 15px;
  background: #ff9800;
  border-radius: 40px;
}

.input-group-filter .filter-addon a {
  float: left;
  padding: 0 10px;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
}

.input-group-filter .filter-addon a:active {
  transform: scale(0.8);
}

.form-float {
  position: absolute;
  top: 100%;
  right: 0;
  width: 320px;
  transform: translate(90px, 0);
  padding: 20px;
  background: #fff;
  border: 1px solid #818286;
  border-radius: 4px;
  transition: all 0.2s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}

.form-float:after,
.form-float:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.form-float:after {
  border-color: rgba(255,255,255,0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}

.form-float:before {
  border-color: rgba(129,130,134,0);
  border-bottom-color: #818286;
  border-width: 9px;
  margin-left: -9px;
}

.form-float.open {
  transform: translate(90px, 15px);
  opacity: 1;
  visibility: visible;
}

.form-panel legend {
  width: 100%;
  display: block;
}

.form-filter .form-control,
.form-filter .custom-file+label {
  color: #ff9800;
  border-color: #ff9800;
}

.form-filter .form-control::-webkit-input-placeholder,
.form-filter .custom-file+label::-webkit-input-placeholder {
  color: #ff9800;
  opacity: 1;
}

.form-filter .form-control:-moz-placeholder,
.form-filter .custom-file+label:-moz-placeholder {
  color: #ff9800;
  opacity: 1;
}

.form-filter .form-control::-moz-placeholder,
.form-filter .custom-file+label::-moz-placeholder {
  color: #ff9800;
  opacity: 1;
}

.form-filter .form-control:-ms-input-placeholder,
.form-filter .custom-file+label:-ms-input-placeholder {
  color: #ff9800;
  opacity: 1;
}

input[type="text"]:focus,
input[type="search"]:focus,
input[type="password"]:focus,
input[type="submit"]:focus,
select:focus,
textarea:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="file"]:focus,
input[type="number"]:focus {
  box-shadow: 0 0 0 0.5px #0151e1;
  outline: none;
}

input#emailNewsletter:focus {
  box-shadow: none;
}

.form-home-filter .form-home-filter-legend {
  padding: 10.5px 0 10.5px 25px;
  text-transform: uppercase;
  font-weight: 700;
}

.form-home-filter .input-group .input-addon {
  background: #fff;
  color: #ff9800;
  box-shadow: 2px 0 0 0 #fff;
}

.form-home-filter .form-control,
.form-home-filter .custom-file+label {
  background: transparent;
  font-style: italic;
  color: #fff;
}

.form-home-filter .form-control::-webkit-input-placeholder,
.form-home-filter .custom-file+label::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}

.form-home-filter .form-control:-moz-placeholder,
.form-home-filter .custom-file+label:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.form-home-filter .form-control::-moz-placeholder,
.form-home-filter .custom-file+label::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.form-home-filter .form-control:-ms-input-placeholder,
.form-home-filter .custom-file+label:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.form-home-filter input[type="submit"] {
  width: 100%;
  display: block;
  padding: 10.5px 5px;
  border-radius: 50px;
  background: #fff;
  color: #ff9800;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
}

.form-home-filter input[type="submit"]:hover,
.form-home-filter input[type="submit"]:focus {
  background: #e6e6e6;
}

.form-newsletter {
  padding: 65px 0 20px;
}

.form-newsletter input {
  width: 100%;
  display: block;
  padding: 6px 10px;
  font-size: 22px;
  background: transparent;
}

.grecaptcha-badge {
  z-index: 16;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  *zoom: 1;
}

.container:before,
.container:after {
  content: "";
  display: table;
}

.container:after {
  clear: both;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  *zoom: 1;
}

.container-fluid:before,
.container-fluid:after {
  content: "";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
  *zoom: 1;
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.no-padding {
  padding: 0 !important;
}

.clearfix,
.card-link,
.half-view {
  *zoom: 1;
}

.clearfix:before,
.card-link:before,
.half-view:before,
.clearfix:after,
.card-link:after,
.half-view:after {
  content: "";
  display: table;
}

.clearfix:after,
.card-link:after,
.half-view:after {
  clear: both;
}

.col-right {
  float: right !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  visibility: hidden;
}

.modal.open {
  visibility: visible;
}

.modal.open .modal-overlay {
  opacity: 1;
}

.modal.open .modal-box {
  transform: translate(-50%, -50%) scale(1);
}

.modal .modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  opacity: 0;
  cursor: url(../img/X.png),auto;
  background: #000;
  background: rgba(0,0,0,0.7);
}

.modal .modal-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
  max-width: 780px;
  width: 90%;
  max-height: 90%;
  padding: 30px 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px -15px #000;
  border: 1px solid #e6e6e6;
  color: #000;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.modal .modal-close {
  position: absolute;
  top: -14px;
  right: -5px;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background: #ff9800;
  color: #fff;
  border: 2px solid #fff;
}

.modal .modal-close:hover,
.modal .modal-close:focus {
  background: #e68900;
}

.modal .modal-header {
  position: relative;
  margin-bottom: 10px;
  padding: 0 10px;
  text-align: center;
}

.modal .modal-header .modal-title {
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 700;
  color: #009688;
}

.modal .modal-content {
  padding: 10px 15px;
  font-size: 20px;
  text-align: center;
  overflow-y: auto;
}

.list-navigation>li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
}

.list-navigation>li:last-child {
  margin: 0;
}

.list-navigation>li.active a {
  color: #ff9800;
}

.list-navigation>li a {
  display: block;
  padding: 5px 0;
  color: #2e2c2d;
  text-transform: uppercase;
  font-size: 16px;
  transition: all 0.2s ease;
}

.list-navigation>li a.location-lang {
  padding: 0;
  font-size: 13px;
}

.list-navigation>li a:hover,
.list-navigation>li a:focus {
  color: #ff9800;
}

.list-navigation>li.mobile-only-search .btn {
  padding: 12px 13px;
}

.list-navigation>li.list-navigation-social a {
  font-size: 24px;
  color: #00bcd4;
  line-height: 24px;
}

.list-navigation>li.list-navigation-social a:hover,
.list-navigation>li.list-navigation-social a:focus {
  transform: scale(0.8);
}

.list-navigation>li.list-navigation-button {
  position: relative;
  z-index: 5;
}

.list-navigation>li.list-navigation-button a {
  position: relative;
  color: #fff;
  z-index: 5;
}

.list-navigation>li.list-navigation-button a#search-trigger.active+.form-search {
  width: 300px;
}

.list-navigation>li.list-navigation-button a i {
  width: 15px;
}

.chance-language>a {
  font-size: 14px;
}

.list-filter-info li {
  padding: 8px 0;
  list-style: none;
  text-transform: uppercase;
}

.list-filter-info li span {
  position: relative;
  top: 1px;
}

.list-filter-info li a {
  color: #fff;
}

.list-filter-info li a:hover,
.list-filter-info li a:focus {
  color: #e6e6e6;
}

.list-filter-info li i {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  line-height: 26px;
  vertical-align: middle;
  text-align: center;
  border-radius: 100%;
  background: #fff;
  color: #ff9800;
}

.list-sitemap .list-sitemap-item {
  list-style: none;
}

.list-sitemap .list-sitemap-item a {
  display: block;
  padding: 15px 0;
  color: #535456;
}

.list-sitemap .list-sitemap-item a:hover,
.list-sitemap .list-sitemap-item a:focus {
  color: #3f51b5;
}

.list-sitemap .list-sitemap-item+.list-sitemap-item {
  border-top: 1px solid #3f51b5;
}

.list-social li {
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
  list-style: none;
}

.list-social li a {
  display: block;
  color: #fff;
  font-size: 26px;
  vertical-align: middle;
  transition: all 0.1s ease;
}

.list-social li a:hover,
.list-social li a:focus {
  transform: scale(0.9);
}

.list-telephone {
  padding-left: 10px;
  border-left: 1px solid #9c27b0;
}

.list-telephone li {
  margin-bottom: 10px;
  list-style: none;
}

.list-advantages {
  *zoom: 1;
}

.list-advantages:before,
.list-advantages:after {
  content: "";
  display: table;
}

.list-advantages:after {
  clear: both;
}

.list-advantages li {
  position: relative;
  width: 33.333%;
  height: 45px;
  padding: 0 10px 0 60px;
  margin-bottom: 15px;
  float: left;
  list-style: none;
}

.list-advantages li i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  vertical-align: middle;
  font-size: 40px;
}

.list-advantages li span {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 16px;
  text-align: left;
}

.list-map-place li {
  position: absolute;
  width: 42px;
  height: 43.5px;
  list-style: none;
}

.list-map-place li.position-01 {
  top: 37%;
  left: 89%;
}

.list-map-place li.position-02 {
  top: 19%;
  left: 69.5%;
}

.list-map-place li.position-03 {
  top: 49%;
  left: 73%;
}

.list-map-place li.position-04 {
  top: 62%;
  left: 68%;
}

.list-map-place li.position-05 {
  top: 54%;
  left: 40%;
}

.list-map-place li.position-06 {
  top: 30%;
  left: 35%;
}

.list-map-place li.position-07 {
  top: 45%;
  left: 26%;
}

.list-map-place li.position-08 {
  top: 76%;
  left: 18%;
}

.list-map-place li.position-09 {
  top: 49%;
  left: 18%;
}

.list-map-place li.position-10 {
  top: 41%;
  left: 10%;
}

.list-map-place li.position-11 {
  top: 28%;
  left: 21%;
}

.list-map-place li.position-12 {
  top: 8%;
  left: 52%;
}

.list-map-place li.position-13 {
  top: 25%;
  left: 51%;
}

.list-map-place li.position-14 {
  top: 18%;
  left: 27%;
}

.list-map-legend {
  font-size: 18px;
}

.list-map-legend>li {
  line-height: 25px;
  list-style-position: inside;
}

.list-map-legend li>strong {
  line-height: 40px;
}

.list-map-legend ul>li {
  margin-left: 20px;
  list-style: none;
}

.btn {
  display: inline-block;
  padding: 8px 12px;
  color: #fff;
  border-radius: 40px;
  border: 2px solid #fff;
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  transition: all 0.1s ease;
}

.btn.btn-link {
  font-size: inherit;
  border-radius: 0;
  text-decoration: underline;
  text-transform: none;
  font-weight: 400;
  border: none;
}

.btn.btn-block {
  width: 100%;
  text-align: center;
  display: block;
}

.btn.btn-sm {
  padding: 9px 16px;
}

.btn.btn-lg {
  padding: 10px 20px;
  font-size: 16px;
}

.btn.btn-default:hover,
.btn.btn-default:focus {
  background: #fff;
  color: #000;
}

.btn.btn-info {
  background: #00bcd4;
  border-color: #00bcd4;
}

.btn.btn-info:hover,
.btn.btn-info:focus {
  background: #00a5bb;
  border-color: #00a5bb;
}

.btn.btn-info.btn-inverse {
  background: #fff;
  color: #00bcd4;
}

.btn.btn-info.btn-inverse:hover,
.btn.btn-info.btn-inverse:focus {
  background: #00a5bb;
  border-color: #00a5bb;
  color: #fff;
}

.btn.btn-success {
  background: #009688;
  border-color: #009688;
}

.btn.btn-success:hover,
.btn.btn-success:focus {
  background: #007d71;
  border-color: #007d71;
}

.btn.btn-success.btn-inverse {
  background: #fff;
  color: #db4437;
}

.btn.btn-success.btn-inverse:hover,
.btn.btn-success.btn-inverse:focus {
  background: #007d71;
  border-color: #007d71;
  color: #fff;
}

.btn.btn-success.btn-link {
  background: none;
  color: #009688;
}

.btn.btn-success.btn-link:hover,
.btn.btn-success.btn-link:focus {
  color: #007d71;
}

.btn.btn-danger {
  background: #db4437;
  border-color: #db4437;
}

.btn.btn-danger:hover,
.btn.btn-danger:focus {
  background: #d33426;
  border-color: #d33426;
}

.btn.btn-danger.btn-inverse {
  background: #fff;
  color: #db4437;
}

.btn.btn-danger.btn-inverse:hover,
.btn.btn-danger.btn-inverse:focus {
  background: #d33426;
  border-color: #d33426;
  color: #fff;
}

.btn.btn-warning {
  background: #ff9800;
  border-color: #ff9800;
}

.btn.btn-warning:hover,
.btn.btn-warning:focus {
  background: #e68900;
  border-color: #e68900;
}

.btn.btn-warning.btn-inverse {
  background: #fff;
  color: #ff9800;
}

.btn.btn-warning.btn-inverse:hover,
.btn.btn-warning.btn-inverse:focus {
  background: #e68900;
  border-color: #e68900;
  color: #fff;
}

.btn.btn-primary {
  background: #3f51b5;
  border-color: #3f51b5;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus {
  background: #3849a2;
  border-color: #3849a2;
}

.btn.btn-primary.btn-inverse {
  background: #fff;
  color: #3f51b5;
}

.btn.btn-primary.btn-inverse:hover,
.btn.btn-primary.btn-inverse:focus {
  background: #3849a2;
  border-color: #3849a2;
  color: #fff;
}

.btn.btn-secondary {
  background: #009688;
  border-color: #009688;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:focus {
  background: #007d71;
  border-color: #007d71;
}

.btn.btn-terciary {
  background: #e91e63;
  border-color: #e91e63;
}

.btn.btn-terciary:hover,
.btn.btn-terciary:focus {
  background: #d81558;
  border-color: #d81558;
}

.btn.btn-fourty {
  background: #9c27b0;
  border-color: #9c27b0;
}

.btn.btn-fourty:hover,
.btn.btn-fourty:focus {
  background: #89229b;
  border-color: #89229b;
}

.btn.btn-orange {
  background: #ff9800 !important;
  border-color: #ff9800 !important;
}

.btn.btn-orange:hover,
.btn.btn-orange:focus {
  background: #e68900 !important;
  border-color: #e68900 !important;
}

.btn.btn-green {
  background: #009688 !important;
  border-color: #009688 !important;
}

.btn.btn-green:hover,
.btn.btn-green:focus {
  background: #007d71 !important;
  border-color: #007d71 !important;
}

.btn.btn-blue {
  background: #00bcd4 !important;
  border-color: #00bcd4 !important;
}

.btn.btn-blue:hover,
.btn.btn-blue:focus {
  background: #00a5bb !important;
  border-color: #00a5bb !important;
}

.btn.btn-orchid {
  background: #3f51b5 !important;
  border-color: #3f51b5 !important;
}

.btn.btn-orchid:hover,
.btn.btn-orchid:focus {
  background: #3849a2 !important;
  border-color: #3849a2 !important;
}

.btn.btn-purple {
  background: #9c27b0 !important;
  border-color: #9c27b0 !important;
}

.btn.btn-purple:hover,
.btn.btn-purple:focus {
  background: #89229b !important;
  border-color: #89229b !important;
}

.btn.btn-pink {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.btn.btn-pink:hover,
.btn.btn-pink:focus {
  background: #d81558 !important;
  border-color: #d81558 !important;
}

.btn.btn-violet {
  background: #673ab7 !important;
  border-color: #673ab7 !important;
}

.btn.btn-violet:hover,
.btn.btn-violet:focus {
  background: #5c34a4 !important;
  border-color: #5c34a4 !important;
}

.btn.btn-red {
  background: #db4437 !important;
  border-color: #db4437 !important;
}

.btn.btn-red:hover,
.btn.btn-red:focus {
  background: #d33426 !important;
  border-color: #d33426 !important;
}

.hero-slider {
  position: relative;
}

.hero-slider-holding {
  position: relative;
}

.hero-slider-stage {
  position: relative;
  height: 535px;
}

.hero-slider-stage div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  background-size: cover;
  background-position: center center;
  z-index: 5;
}

.hero-slider-stage div.ativo {
  visibility: visible;
  opacity: 1;
}

.hero-slider-stage .container {
  position: relative;
  height: 100%;
  z-index: 5;
}

.hero-slider-stage .content {
  position: relative;
  top: 50%;
  width: 100%;
  max-width: 650px;
  transform: translateY(-50%);
  color: #fff;
}

.hero-slider-stage p {
  position: relative;
  font-weight: 700;
  font-size: 42px;
  line-height: 45px;
  text-transform: uppercase;
}

.hero-slider-nav .hero-slider-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 6px 18px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  border-radius: 10px;
  transition: all 0.2s ease;
  z-index: 5;
}

.hero-slider-nav .hero-slider-navigation.to-left {
  left: 10%;
}

.hero-slider-nav .hero-slider-navigation.to-right {
  right: 10%;
}

.hero-slider-nav .hero-slider-navigation:hover,
.hero-slider-nav .hero-slider-navigation:focus {
  background: #fff;
  color: #2e2c2d;
}

.hero-slider-bullets {
  position: absolute;
  width: 100%;
  bottom: 50px;
  left: 0;
  text-align: center;
  z-index: 15;
}

.hero-slider-bullets>a {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  margin: 0 4px;
  background: #fff;
}

.hero-slider-bullets>a.ativo {
  background: #ccc;
}

.hero-slider-bullets>a:hover,
.hero-slider-bullets>a:focus {
  background: #ccc;
}

.banner-video {
  position: relative;
  height: 640px;
  background: url(../video/background.jpg) no-repeat center center;
  overflow: hidden;
}

.banner-video:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 180px;
  display: block;
  background: transparent;
  background: -moz-linear-gradient(top, transparent, #000);
  background: -ms-linear-gradient(top, transparent, #000);
  background: -o-linear-gradient(top, transparent, #000);
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000));
  background: -webkit-linear-gradient(top, transparent, #000);
  z-index: 5;
}

.card-visual {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.card-visual .card-visual-title {
  padding: 15px 10px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: #ff9800;
  text-indent: 4px;
}

.card-visual .card-visual-image.height-fixed {
  height: 320px;
}

.card-visual .card-visual-image.height-fixed img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-visual .card-visual-link {
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 85%;
  transform: translateX(-50%);
  display: block;
  padding: 12px 20px;
  border-radius: 40px;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  background: #ff9800;
  opacity: 0.9;
  transition: all 0.1s ease;
  z-index: 5;
}

.card-visual .card-visual-link:hover,
.card-visual .card-visual-link:focus {
  opacity: 1;
}

.card-visual.blue .card-visual-title {
  background: #00bcd4;
}

.card-visual.blue .card-visual-link {
  background: #00bcd4;
}

.card-visual.purple .card-visual-title {
  background: #9c27b0;
}

.card-visual.purple .card-visual-link {
  background: #9c27b0;
}

.card-visual.orchid .card-visual-title {
  background: #3f51b5;
}

.card-visual.orchid .card-visual-link {
  background: #3f51b5;
}

.card-visual.pink .card-visual-title {
  background: #e91e63;
}

.card-visual.pink .card-visual-link {
  background: #e91e63;
}

.card-visual.red .card-visual-title {
  background: #db4437;
}

.card-visual.red .card-visual-link {
  background: #db4437;
}

.card-visual.green .card-visual-title {
  background: #009688;
}

.card-visual.green .card-visual-link {
  background: #009688;
}

.card-visual.orange .card-visual-title {
  background: #ff9800;
}

.card-visual.orange .card-visual-link {
  background: #ff9800;
}

.card-visual.violet .card-visual-title {
  background: #673ab7;
}

.card-visual.violet .card-visual-link {
  background: #673ab7;
}

.card-collapse {
  position: relative;
  height: 640px;
}

.card-collapse.active .card-collapse-content {
  height: 100%;
}

.card-collapse .card-collapse-image {
  width: 100%;
  height: 100%;
}

.card-collapse .card-collapse-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-collapse .card-collapse-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 190px;
  padding: 40px 15px;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease;
  background: #ff9800;
  background: rgba(255,152,0,0.9);
}

.card-collapse .card-collapse-title {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
}

.card-collapse .card-collapse-description {
  height: 420px;
  display: none;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-collapse .card-collapse-button {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.card-collapse.green .card-collapse-content {
  background: #009688;
  background: rgba(0,150,136,0.9);
}

.card-collapse.red .card-collapse-content {
  background: #db4437;
  background: rgba(219,68,55,0.9);
}

.card-collapse.blue .card-collapse-content {
  background: #00bcd4;
  background: rgba(0,188,212,0.8);
}

.card-collapse.purple .card-collapse-content {
  background: #9c27b0;
  background: rgba(156,39,176,0.9);
}

.card-collapse.orchid .card-collapse-content {
  background: #3f51b5;
  background: rgba(63,81,181,0.9);
}

.card-post .card-post-link {
  position: relative;
  width: 100%;
  height: 270px;
  display: block;
  color: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.card-post .card-post-link:hover .card-post-content,
.card-post .card-post-link:focus .card-post-content {
  transform: translateY(0);
}

.card-post .card-post-link:hover .card-post-image img,
.card-post .card-post-link:focus .card-post-image img {
  transform: scale(1.1);
}

.card-post .card-post-image {
  width: 100%;
  height: 100%;
}

.card-post .card-post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
}

.card-post .card-post-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 30px;
  background: #ff9800;
  transition: all 0.2s ease;
}

.card-post .card-post-title {
  margin-bottom: 20px;
  font-size: 20px;
}

.card-post .card-post-description {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-post.orchid .card-post-content {
  background: #3f51b5;
}

.card-post.violet .card-post-content {
  background: #673ab7;
}

.card-post.purple .card-post-content {
  background: #9c27b0;
}

.card-table {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.card-table .card-table-column {
  display: table-cell;
  padding: 50px;
  background: #ff9800;
  vertical-align: middle;
}

.card-table .card-table-column .card-table-column-content {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  text-align: center;
}

.card-table .card-table-column .card-table-column-content a {
  color: inherit;
}

.card-table .card-table-column .card-table-column-content a:hover,
.card-table .card-table-column .card-table-column-content a:focus {
  color: #e6e6e6;
}

.card-table .card-table-column .card-table-column-icons {
  text-align: center;
}

.card-table .card-table-column .card-table-column-icons i {
  margin: 5px;
  font-size: 60px;
  vertical-align: middle;
}

.card-table .card-table-column .card-table-column-icons span {
  margin: 5px 0 5px -10px;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  line-height: 32px;
  font-size: 25px;
  font-weight: 700;
}

.card-table .card-table-column.orange {
  background: #ff9800;
}

.card-table .card-table-column.green {
  background: #009688;
}

.card-table .card-table-column.blue {
  background: #00bcd4;
}

.card-table .card-table-column.orchid {
  background: #3f51b5;
}

.card-table .card-table-column.purple {
  background: #9c27b0;
}

.card-table .card-table-column.pink {
  background: #e91e63;
}

.card-table .card-table-column.violet {
  background: #673ab7;
}

.card-table .card-table-column.red {
  background: #db4437;
}

.card-link {
  width: 100%;
}

.card-link .card-link-box {
  position: relative;
  width: 100%;
  height: 385px;
  float: left;
  color: #fff;
}

.card-link .card-link-box:hover .card-link-box-content,
.card-link .card-link-box:focus .card-link-box-content {
  opacity: 1;
  visibility: visible;
}

.card-link .card-link-box.active .card-link-popover {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.card-link .card-link-box>a {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
}

.card-link .card-link-box .card-link-box-image {
  width: 100%;
  height: 100%;
}

.card-link .card-link-box .card-link-box-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-link .card-link-box .card-link-box-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  overflow: hidden;
  transition: all 0.2s ease;
  background: #009688;
  background: rgba(0,150,136,0.8);
}

.card-link .card-link-box .card-link-box-content .card-link-box-content-text {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  padding: 10px 35px;
}

.card-link .card-link-box .card-link-box-content .card-link-box-content-text p {
  margin-bottom: 25px;
  font-weight: 300;
  font-size: 20px;
}

.card-link .card-link-box .card-link-popover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px 40px;
  background: #009688;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transform: scale(0);
}

.card-link .card-link-box .card-link-popover .card-link-popover-content {
  height: 100%;
  overflow-y: auto;
}

.card-link .card-link-box .card-link-popover .card-link-popover-close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
}

.card-link .card-link-box .card-link-popover .card-link-popover-close:hover,
.card-link .card-link-box .card-link-popover .card-link-popover-close:focus {
  color: #e6e6e6;
}

.card-list .card-list-item+.card-list-item {
  padding-top: 30px;
  border-top: 1px solid #ff9800;
}

.card-list .card-list-item-image {
  height: 250px;
}

.card-list .card-list-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.card-list .card-list-item-content i {
  font-size: 38px;
}

.card-list .card-list-item-title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #ff9800;
  text-transform: uppercase;
}

.card-list .card-list-item-description {
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 10px;
}

.title-default {
  font-size: 42px;
  font-weight: 300;
}

.title-bubble {
  padding: 10px 20px;
  font-weight: 300;
  text-align: center;
  font-size: 40px;
  background: #ff9800;
  border-radius: 40px;
  color: #fff;
}

.title-pointer {
  padding: 15px 10px;
  text-align: center;
  font-size: 22px;
  font-weight: 300;
  color: #fff;
}

.title-xl {
  font-size: 28px;
}

.title-orange {
  background: #ff9800;
  background: #ff9800;
}

.title-red {
  background: #db4437;
  background: #db4437;
}

.title-green {
  background: #009688;
  background: #009688;
}

.title-violet {
  background: #673ab7;
  background: #673ab7;
}

.title-purple {
  background: #9c27b0;
  background: #9c27b0;
}

.title-pink {
  background: #e91e63;
  background: #e91e63;
}

.title-blue {
  background: #00bcd4;
  background: #00bcd4;
}

.title-orchid {
  background: #3f51b5;
  background: #3f51b5;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-color-0,
.text-color-white {
  color: #fff;
}

.text-color-1,
.text-color-orange {
  color: #ff9800 !important;
}

.text-color-2,
.text-color-green {
  color: #009688 !important;
}

.text-color-3,
.text-color-blue {
  color: #00bcd4 !important;
}

.text-color-4,
.text-color-orchid {
  color: #3f51b5 !important;
}

.text-color-5,
.text-color-purple {
  color: #9c27b0 !important;
}

.text-color-6,
.text-color-pink {
  color: #e91e63 !important;
}

.text-color-7,
.text-color-violet {
  color: #673ab7 !important;
}

.text-color-8,
.text-color-red {
  color: #db4437 !important;
}

.text-tag {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 40px;
  color: #fff;
}

.text-tag.text-tag-warning {
  background: #ff9800;
}

.text-tag.text-tag-lg {
  padding: 9px 20px;
}

.text-lg {
  font-size: 22px;
  font-weight: 300;
}

.text-md {
  font-size: 18px;
  font-weight: 300;
}

.text-sm {
  font-size: 14px;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.newsletter {
  position: relative;
  margin-bottom: 40px;
  background: #efeeee;
  overflow: hidden;
}

.newsletter .newsletter-header {
  position: relative;
  padding: 40px 70px 40px 0;
  color: #fff;
  text-align: right;
}

.newsletter .newsletter-header>* {
  position: relative;
}

.newsletter .newsletter-header .newsletter-header-title {
  font-size: 42px;
  font-weight: 300;
}

.newsletter .newsletter-header:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1920px;
  height: 100%;
  display: block;
  background: #009688;
  border-bottom-right-radius: 140px;
  border-top-right-radius: 140px;
}

/*!
 * Datepicker for Bootstrap v1.6.4 (https://github.com/eternicode/bootstrap-datepicker)
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.datepicker {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker.datepicker-rtl {
  direction: rtl;
}

.datepicker.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0,0,0,0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #eee;
  cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #efe24b \9;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, gray);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, gray);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(gray));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, gray);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, gray);
  background-image: linear-gradient(to bottom, #b3b3b3, gray);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: #808080 #808080 #595959;
  border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #808080;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #666666 \9;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background: #ff9800;
  color: #fff;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background: #ff9800;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background: #ff9800;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #eee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) rgba(0,0,0,0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #0044cc;
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #003399 \9;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 20px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}

.datepicker.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,0.2);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  color: #333333;
  font-size: 13px;
  line-height: 20px;
}

.datepicker.dropdown-menu th,
.datepicker.datepicker-inline th,
.datepicker.dropdown-menu td,
.datepicker.datepicker-inline td {
  padding: 4px 5px;
}

.inline-block {
  display: inline-block;
}

.page-hero {
  position: relative;
  height: 700px;
  background-size: cover;
  background-position: center center;
}

.page-hero.orange .page-hero-title {
  background: #ff9800;
  background: rgba(255,152,0,0.8);
}

.page-hero.red .page-hero-title {
  background: #db4437;
  background: rgba(219,68,55,0.8);
}

.page-hero.green .page-hero-title {
  background: #009688;
  background: rgba(0,150,136,0.8);
}

.page-hero.violet .page-hero-title {
  background: #673ab7;
  background: rgba(103,58,183,0.8);
}

.page-hero.purple .page-hero-title {
  background: #9c27b0;
  background: rgba(156,39,176,0.8);
}

.page-hero.pink .page-hero-title {
  background: #e91e63;
  background: rgba(233,30,99,0.8);
}

.page-hero.blue .page-hero-title {
  background: #00bcd4;
  background: rgba(0,188,212,0.8);
}

.page-hero.orchid .page-hero-title {
  background: #3f51b5;
  background: rgba(63,81,181,0.8);
}

.page-hero .page-hero-inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
  z-index: 5;
}

.page-hero .page-hero-title {
  display: inline-block;
  padding: 8px 40px;
  font-size: 52px;
  font-weight: 300;
  color: #fff;
  border-radius: 40px;
}

.page-header {
  position: relative;
  height: 300px;
  background-position: center center;
  background-repeat: no-repeat;
}

.page-header.orange:before {
  background: #ff9800;
  background: rgba(255,152,0,0.8);
}

.page-header.red:before {
  background: #db4437;
  background: rgba(219,68,55,0.8);
}

.page-header.green:before {
  background: #009688;
  background: rgba(0,150,136,0.8);
}

.page-header.violet:before {
  background: #673ab7;
  background: rgba(103,58,183,0.8);
}

.page-header.purple:before {
  background: #9c27b0;
  background: rgba(156,39,176,0.8);
}

.page-header.pink:before {
  background: #e91e63;
  background: rgba(233,30,99,0.8);
}

.page-header.blue:before {
  background: #00bcd4;
  background: rgba(0,188,212,0.8);
}

.page-header.orchid:before {
  background: #3f51b5;
  background: rgba(63,81,181,0.8);
}

.page-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.page-header .page-header-inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  color: #fff;
  z-index: 5;
}

.page-header .page-header-title {
  font-weight: 300;
  font-size: 52px;
  line-height: 52px;
}

.page-header-custom {
  position: relative;
}

.page-header-custom.orange .page-header-custom-title {
  background: #ff9800;
  background: #ff9800;
}

.page-header-custom.red .page-header-custom-title {
  background: #db4437;
  background: #db4437;
}

.page-header-custom.green .page-header-custom-title {
  background: #009688;
  background: #009688;
}

.page-header-custom.violet .page-header-custom-title {
  background: #673ab7;
  background: #673ab7;
}

.page-header-custom.purple .page-header-custom-title {
  background: #9c27b0;
  background: #9c27b0;
}

.page-header-custom.pink .page-header-custom-title {
  background: #e91e63;
  background: #e91e63;
}

.page-header-custom.blue .page-header-custom-title {
  background: #00bcd4;
  background: #00bcd4;
}

.page-header-custom.orchid .page-header-custom-title {
  background: #3f51b5;
  background: #3f51b5;
}

.page-header-custom .page-header-custom-title {
  padding: 20px 10px;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  color: #fff;
}

.page-header-sm {
  padding: 30px;
  text-align: center;
}

.page-header-sm.orange {
  background: #ff9800;
  background: #ff9800;
}

.page-header-sm.red {
  background: #db4437;
  background: #db4437;
}

.page-header-sm.green {
  background: #009688;
  background: #009688;
}

.page-header-sm.violet {
  background: #673ab7;
  background: #673ab7;
}

.page-header-sm.purple {
  background: #9c27b0;
  background: #9c27b0;
}

.page-header-sm.pink {
  background: #e91e63;
  background: #e91e63;
}

.page-header-sm.blue {
  background: #00bcd4;
  background: #00bcd4;
}

.page-header-sm.orchid {
  background: #3f51b5;
  background: #3f51b5;
}

.page-header-sm .page-header-sm-title {
  font-weight: 300;
  font-size: 50px;
  color: #fff;
}

.page-return {
  position: relative;
  padding: 70px 0;
  text-align: center;
  font-weight: 300;
  font-size: 22px;
  color: #fff;
  background-image: url(../img/errors/background.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-return:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: #000;
  opacity: 0.6;
}

.page-return .container {
  position: relative;
}

.page-return .page-return-title {
  font-size: 52px;
  font-weight: 700;
}

.page-return .page-return-subtitle {
  margin-bottom: 40px;
  font-size: 36px;
  line-height: 36px;
}

.page-return .page-return-description {
  margin-bottom: 40px;
}

.jumbotron {
  position: relative;
  height: 450px;
  color: #fff;
  text-align: center;
}

.jumbotron:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: #000;
  background: rgba(0,0,0,0.5);
}

.jumbotron .jumbotron-inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  z-index: 5;
}

.jumbotron .jumbotron-title {
  margin-bottom: 5px;
  font-size: 52px;
  font-weight: 300;
}

.jumbotron .jumbotron-description {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 300;
}

.quote-hero {
  padding: 50px 30px;
  font-weight: 300;
  text-align: center;
  color: #2e2c2d;
}

.quote-hero .quote-hero-text {
  font-size: 42px;
  line-height: 56px;
}

.half-view {
  position: relative;
  width: 100%;
  display: table;
  table-layout: fixed;
  overflow: hidden;
}

.half-view .view {
  width: 100%;
  display: table-cell;
  padding: 40px;
  background: #ff9800;
  color: #fff;
  font-size: 52px;
  font-weight: 300;
}

.half-view .view.red {
  background: #db4437;
}

.half-view .view.orange {
  background: #ff9800;
}

.half-view .view-append {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 100%;
  color: #db4437;
}

.row-fluid {
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
}

.col-fluid {
  float: left;
}

.col-fluid.col-fluid-media {
  margin: 0;
}

.col-fluid.col-fluid-right {
  margin: 0;
}

.col-fluid.col-fluid-right.col-fluid-media {
  left: auto;
  right: 0;
}

.col-fluid.col-fluid-right.col-fluid-media:before {
  left: 0;
  right: auto;
  border-right-color: transparent;
  border-left-color: #fff;
}

.col-fluid .fluid-image {
  width: 100%;
  height: 100%;
}

.col-fluid .fluid-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.col-fluid .fluid-iframe {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}

.col-fluid .carousel-fluid {
  height: 100%;
}

.col-fluid .carousel-fluid .owl-stage-outer,
.col-fluid .carousel-fluid .owl-stage,
.col-fluid .carousel-fluid .owl-item,
.col-fluid .carousel-fluid .carousel-item {
  height: 100%;
}

.col-fluid .carousel-fluid .carousel-item .carousel-item-content {
  bottom: 70px;
}

.col-fluid .fluid-content {
  padding: 30px 20px;
}

.filter-clear {
  width: 100%;
  display: block;
  padding: 10px 0;
  background: #ff9800;
  color: #fff;
  font-size: 42px;
  text-align: center;
}

.filter-clear:hover,
.filter-clear:focus {
  background: #e68900;
}

.menu-inside.menu-inside-violet .menu-inside-title {
  color: #673ab7;
}

.menu-inside.menu-inside-violet .menu-inside-list li a:hover,
.menu-inside.menu-inside-violet .menu-inside-list li a:focus {
  color: #673ab7;
}

.menu-inside.menu-inside-violet .menu-inside-list li a.active {
  color: #673ab7;
}

.menu-inside .menu-inside-title {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 28px;
}

.menu-inside .menu-inside-list {
  padding-left: 15px;
}

.menu-inside .menu-inside-list li {
  list-style: none;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 5px;
}

.menu-inside .menu-inside-list li a {
  display: block;
  color: inherit;
}

.search-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 100;
}

.search-fixed.active {
  opacity: 1;
  visibility: visible;
}

.search-fixed.active .search-fixed-content {
  transform: translate(-50%, -50%) scale(1);
}

.search-fixed .search-fixed-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  background: rgba(0,0,0,0.85);
}

.search-fixed .search-fixed-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  max-width: 500px;
  width: 100%;
  padding: 10px 20px;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.carousel-hero {
  position: relative;
}

.carousel-hero .carousel-item {
  position: relative;
  height: 780px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-hero .carousel-item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 180px;
  display: block;
  opacity: 0.6;
  background: transparent;
  background: -moz-linear-gradient(top, transparent, #000);
  background: -ms-linear-gradient(top, transparent, #000);
  background: -o-linear-gradient(top, transparent, #000);
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000));
  background: -webkit-linear-gradient(top, transparent, #000);
}

.carousel-hero .carousel-item .carousel-item-content {
  position: absolute;
  bottom: 150px;
  left: 0;
  width: 100%;
  padding: 0 80px;
  color: #fff;
  text-align: center;
  font-weight: 300;
  font-size: 22px;
  z-index: 5;
}

.carousel-hero .owl-controls .owl-prev {
  display: block !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 6px 18px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 9;
  left: 15px;
}

.carousel-hero .owl-controls .owl-prev:hover {
  background: #fff;
  color: #2e2c2d;
}

.carousel-hero .owl-controls .owl-next {
  display: block !important;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 6px 18px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 5;
  right: 15px;
}

.carousel-hero .owl-controls .owl-next:hover {
  background: #fff;
  color: #2e2c2d;
}

.carousel-hero .owl-controls .owl-dots {
  display: none !important;
}

.carousel-hero .owl-controls .owl-dot {
  width: 15px;
  height: 15px;
  margin: 0 8px;
  background: #fff;
  border-radius: 100%;
  display: inline-block;
}

.carousel-hero .owl-controls .owl-dot.active {
  background: #ff9800;
}

.carousel-card-link .owl-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 25px 0;
  text-align: center;
}

.carousel-card-link .owl-controls .owl-dot {
  width: 15px;
  height: 15px;
  margin: 0 8px;
  background: #fff;
  border-radius: 100%;
  display: inline-block;
}

.carousel-card-link .owl-controls .owl-dot.active {
  background: #ff9800;
}

.border-top {
  position: relative;
  border-top: solid 12px;
}

.border-top:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  border-top-color: inherit;
  border-width: 12px;
  margin-left: -12px;
  z-index: 5;
}

.border-top.border-outside {
  border-top: 0;
}

.border-top.border-outside:after {
  top: -24px;
  border-bottom-color: inherit;
  border-top-color: transparent;
}

.border-bottom {
  position: relative;
  border-bottom: solid 12px;
}

.border-bottom:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  pointer-events: none;
  border-bottom-color: inherit;
  border-width: 12px;
  margin-left: -12px;
  z-index: 5;
}

.border-bottom.border-outside {
  border-bottom: 0;
}

.border-bottom.border-outside:after {
  bottom: -24px;
  border-top-color: inherit;
  border-bottom-color: transparent;
}

.border-color-1,
.border-orange {
  border-color: #ff9800;
}

.border-color-2,
.border-green {
  border-color: #009688;
}

.border-color-3,
.border-blue {
  border-color: #00bcd4;
}

.border-color-4,
.border-orchid {
  border-color: #3f51b5;
}

.border-color-5,
.border-purple {
  border-color: #9c27b0;
}

.border-color-6,
.border-pink {
  border-color: #e91e63;
}

.border-color-7,
.border-violet {
  border-color: #673ab7;
}

.border-color-8,
.border-red {
  border-color: #db4437;
}

.accordion .accordion-title {
  position: relative;
  display: block;
  padding-bottom: 10px;
  margin-top: 20px;
  font-size: 22px;
  font-weight: 300;
  cursor: pointer;
  border-bottom: 1px solid #db4437;
}

.accordion .accordion-title:hover,
.accordion .accordion-title:focus {
  color: #db4437;
}

.accordion .accordion-title.active {
  color: #db4437;
}

.accordion .accordion-title.active:before {
  transform: translateY(-50%) rotate(180deg);
}

.accordion .accordion-title:before {
  content: "\f107";
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(0deg);
  color: #db4437;
  font-size: 30px;
  transition: all 0.2s ease;
}

.accordion .accordion-content {
  display: none;
  padding: 20px;
  background: #f1f1f1;
  font-size: 18px;
}

.accordion .accordion-content li {
  list-style-position: inside;
}

.table {
  width: 100%;
  border-collapse: collapse;
  font-weight: 700;
  text-align: center;
}

.table.table-orange thead {
  background: #ff9800;
}

.table.table-green thead {
  background: #009688;
}

.table.table-blue thead {
  background: #00bcd4;
}

.table.table-orchid thead {
  background: #3f51b5;
}

.table.table-purple thead {
  background: #9c27b0;
}

.table.table-pink thead {
  background: #e91e63;
}

.table.table-violet thead {
  background: #673ab7;
}

.table.table-red thead {
  background: #db4437;
}

.table thead {
  background: #ff9800;
  color: #fff;
}

.table tbody tr:nth-child(odd) {
  background: #eeeded;
}

.table th,
.table td {
  padding: 15px 5px;
}

body.loading {
  overflow: hidden !important;
}

.default-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  text-align: center;
  z-index: 10000;
}

.default-loading img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

[data-tooltip] {
  position: relative;
  display: block;
  cursor: help;
}

[data-tooltip]:hover:after,
[data-tooltip]:focus:after {
  opacity: 1;
  transform: translate(-50%, 0);
  visibility: visible;
}

[data-tooltip]:after {
  position: absolute;
  content: attr(data-tooltip);
  bottom: 105%;
  left: 50%;
  transform: translate(-50%, -10px);
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 13px;
  color: #fff;
  text-align: center;
  min-width: 80px;
  background: #000;
  background: rgba(0,0,0,0.8);
  opacity: 0;
  transition: all 0.2s ease;
  visibility: hidden;
  z-index: 5;
}

.header {
  background: #fff;
}

.navigation {
  position: relative;
  *zoom: 1;
}

.navigation:before,
.navigation:after {
  content: "";
  display: table;
}

.navigation:after {
  clear: both;
}

.navigation .content-left {
  padding: 28px 0;
  float: left;
}

.navigation .content-right {
  padding: 44.5px 0;
  float: right;
}

.logo a {
  display: block;
}

.menu {
  position: relative;
  background: #ff9800;
}

.menu .menu-list .menu-list-item {
  position: relative;
  display: inline-block;
  transform: translateX(-10px);
}

.menu .menu-list .menu-list-item.active>a {
  background: #ff5b00;
}

.menu .menu-list .menu-list-item.custom {
  transform: translateX(0);
}

.menu .menu-list .menu-list-item>a {
  display: block;
  padding: 10px 11.5px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.menu .menu-list .menu-list-item>a:hover,
.menu .menu-list .menu-list-item>a:focus {
  background: #f08f00;
}

.menu .btn-menu-hamburguer {
  display: block;
  text-align: center;
  display: block;
  padding: 10px 17px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.footer .footer-content {
  margin-bottom: 20px;
  color: #535456;
}

.footer .footer-content .fa {
  vertical-align: middle;
}

.footer .footer-content .footer-content-title {
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #3f51b5;
}

.footer .footer-credits {
  padding: 10px 0;
  background: #e91e63;
  color: #fff;
}

.footer .footer-credits .footer-credits-copyright {
  padding: 5px 0;
}

.footer .footer-credits .footer-credits-logo {
  padding: 5px 0;
  display: block;
  text-align: right;
}

.section-default {
  padding: 55px 0;
}

.section-painted {
  padding: 25px 0;
}

.section-painted.purple {
  background: #9c27b0;
}

.promotions {
  position: relative;
  z-index: 10;
}

.tripadvisor {
  padding: 40px 0;
}

.home-blog {
  padding: 20px 0 50px;
}

.work-with {
  padding: 50px 0;
}

.filter {
  padding: 40px 0 20px;
}

.filters {
  position: relative;
  margin-bottom: 40px;
  background: #ff9800;
  color: #fff;
  z-index: 10;
}

.filters .filters-container {
  padding: 12px 0;
  border-radius: 30px;
  background: #ff9800;
  background: rgba(255,152,0,0.9);
}

.testimonials {
  padding: 50px 0;
  background: #00bcd4;
  color: #fff;
}

.testimonials .testimonial-title {
  font-weight: 300;
  font-size: 36px;
  text-align: center;
}

.testimonials .testimonial-box {
  position: relative;
}

.testimonials .testimonial-box .testimonial-box-image img {
  width: 100px;
  height: 100px;
  display: inline-block;
  object-fit: cover;
  border-radius: 100%;
}

.testimonials .testimonial-box .testimonial-box-name {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 10px;
}

.testimonials .testimonial-box .testimonial-box-depoiment {
  font-style: italic;
}

.instagram-feed {
  padding: 50px 0;
}

.instagram-feed .instagram-title {
  padding: 0 10px;
  margin-bottom: 25px;
  text-align: center;
}

.instagram-feed .instagram-title a {
  display: inline-block;
  padding: 5px 40px;
  font-size: 42px;
  font-weight: 300;
  background: #e91e63;
  border-radius: 40px;
  color: #fff;
  transition: all 0.2s ease;
}

.instagram-feed .instagram-title a:hover,
.instagram-feed .instagram-title a:focus {
  transform: scale(0.95);
}

.instagram-feed .instagram-feed-container {
  position: relative;
  border-top: 10px solid #e91e63;
}

.instagram-feed .instagram-feed-container .instagram-feed-content {
  position: relative;
  width: 100%;
  height: 320px;
  display: block;
}

.instagram-feed .instagram-feed-container .instagram-feed-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.instagram-feed .instagram-feed-container .instagram-feed-content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: #e91e63;
  opacity: 0;
  transition: all 0.2s ease;
}

.instagram-feed .instagram-feed-container .instagram-feed-content:hover:before,
.instagram-feed .instagram-feed-container .instagram-feed-content:focus:before {
  opacity: 0.6;
}

.instagram-feed .instagram-feed-container:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
  pointer-events: none;
  border-color: rgba(136,183,213,0);
  border-bottom-color: #e91e63;
  border-width: 20px;
  margin-left: -20px;
}

.resort-map {
  position: relative;
  overflow-x: hidden;
}

.resort-map-legend {
  padding: 50px 0;
  background: #009688;
}

.tayaya-mascot .tayaya-mascot-inner {
  background: #009688 url("../img/tayaya/mascote-background.jpg") no-repeat center center;
  background-size: cover;
}

.tayaya-mascot .tayaya-mascot-description {
  position: relative;
  padding: 150px 0;
  text-align: center;
  z-index: 5;
}

.tayaya-mascot .tayaya-mascot-description:before {
  content: "";
  position: absolute;
  top: 65px;
  left: -130px;
  width: 697px;
  height: 543px;
  background: url("../img/tayaya/mascote-text-background.png") no-repeat center center;
  z-index: -1;
}

.tayaya-mascot .tayaya-mascot-description .tayaya-mascot-title {
  margin-bottom: 15px;
  font-size: 90px;
  font-weight: 700;
  color: #008b2f;
}

.tayaya-mascot .tayaya-mascot-description .tayaya-mascot-text {
  font-size: 22px;
  font-weight: 300;
}

.tayaya-mascot .tayaya-mascot-image {
  margin-top: 70px;
  text-align: right;
}

.video-sec {
  height: 650px;
}

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 480px) {
  .wd480 {
    display: none;
  }
}

@media (min-width: 768px) {
  .container {
    width: 740px;
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.66667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-5 {
    width: 41.66667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33333%;
  }

  .col-sm-8 {
    width: 66.66667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33333%;
  }

  .col-sm-11 {
    width: 91.66667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.66667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.33333%;
  }

  .col-sm-pull-5 {
    right: 41.66667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.33333%;
  }

  .col-sm-pull-8 {
    right: 66.66667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.33333%;
  }

  .col-sm-pull-11 {
    right: 91.66667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.66667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.33333%;
  }

  .col-sm-push-5 {
    left: 41.66667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.33333%;
  }

  .col-sm-push-8 {
    left: 66.66667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.33333%;
  }

  .col-sm-push-11 {
    left: 91.66667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 768px) {
  .list-filter-info li {
    display: inline-block;
  }

  .list-filter-info li+li {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 2px solid #fff;
  }

  .list-map-legend {
    column-count: 3;
    column-gap: 20px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.66667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33333%;
  }

  .col-md-5 {
    width: 41.66667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33333%;
  }

  .col-md-8 {
    width: 66.66667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33333%;
  }

  .col-md-11 {
    width: 91.66667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.66667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.33333%;
  }

  .col-md-pull-5 {
    right: 41.66667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.33333%;
  }

  .col-md-pull-8 {
    right: 66.66667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.33333%;
  }

  .col-md-pull-11 {
    right: 91.66667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.66667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.33333%;
  }

  .col-md-push-5 {
    left: 41.66667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.33333%;
  }

  .col-md-push-8 {
    left: 66.66667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.33333%;
  }

  .col-md-push-11 {
    left: 91.66667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 992px) {
  .card-post .card-post-content {
    transform: translateY(82px);
  }

  .card-post .card-post-description {
    height: 63px;
  }

  .card-link .card-link-box .card-link-box-content {
    opacity: 0;
    visibility: hidden;
  }

  .col-fluid {
    width: 50%;
    margin-left: 50%;
  }

  .col-fluid.col-fluid-media {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .col-fluid.col-fluid-media:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    border-right-color: #fff;
    border-width: 12px;
    margin-top: -12px;
    z-index: 5;
  }

  .col-fluid.col-fluid-right .fluid-content {
    float: right;
    padding: 35px 35px 35px 20px;
    text-align: right;
  }

  .col-fluid.col-fluid-right .fluid-content .fluid-content-action a {
    float: right;
    margin: 0 0 0 15px;
  }

  .col-fluid.col-fluid-right .fluid-content .fluid-content-action span {
    float: right;
  }

  .col-fluid .fluid-content {
    max-width: 600px;
    width: 100%;
    padding: 35px 20px 35px 35px;
  }

  .col-fluid .fluid-content .fluid-content-action {
    *zoom: 1;
  }

  .col-fluid .fluid-content .fluid-content-action:before,
  .col-fluid .fluid-content .fluid-content-action:after {
    content: "";
    display: table;
  }

  .col-fluid .fluid-content .fluid-content-action:after {
    clear: both;
  }

  .col-fluid .fluid-content .fluid-content-action a {
    float: left;
    margin: 0 15px 0 0;
  }

  .col-fluid .fluid-content .fluid-content-action span {
    float: left;
    padding: 8.5px 0;
  }

  .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px 0;
  }

  .menu .menu-list {
    display: block !important;
  }

  .menu .menu-list .menu-list-item.mobile-only {
    display: none;
  }

  .menu .menu-list .menu-list-item.custom {
    float: right;
  }

  .menu .menu-list .menu-list-item.custom>a {
    padding: 6px 17px;
    margin: 4px 0;
    border-radius: 30px;
    color: #ff9800;
    background: #fff;
  }

  .menu .menu-list .menu-list-item.custom>a:hover,
  .menu .menu-list .menu-list-item.custom>a:focus {
    background: #f2f2f2;
  }

  .menu .btn-menu-hamburguer {
    display: none;
  }

  .testimonials .testimonial-title {
    padding: 0 0 35px;
    text-align: center;
  }

  .testimonials .testimonial-box .testimonial-box-image {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding-left: 30px;
  }

  .tayaya-mascot .tayaya-mascot-inner {
    height: 660px;
  }

  .wd992 {
    display: none;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1180px;
  }

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.66667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33333%;
  }

  .col-lg-5 {
    width: 41.66667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33333%;
  }

  .col-lg-8 {
    width: 66.66667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33333%;
  }

  .col-lg-11 {
    width: 91.66667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.66667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.33333%;
  }

  .col-lg-pull-5 {
    right: 41.66667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.33333%;
  }

  .col-lg-pull-8 {
    right: 66.66667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.33333%;
  }

  .col-lg-pull-11 {
    right: 91.66667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.66667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.33333%;
  }

  .col-lg-push-5 {
    left: 41.66667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.33333%;
  }

  .col-lg-push-8 {
    left: 66.66667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.33333%;
  }

  .col-lg-push-11 {
    left: 91.66667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .half-view .view.left {
    padding-right: 70px;
    text-align: right;
  }

  .half-view .view.right {
    padding-left: 70px;
    text-align: left;
  }

  .filters {
    margin: -130px 0 40px;
    background: transparent;
  }
}

@media screen and (max-width: 1600px) {
  .hero-slider-nav .hero-slider-navigation.to-left {
    left: 15px;
  }

  .hero-slider-nav .hero-slider-navigation.to-right {
    right: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .hero-slider-stage .content {
    max-width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .form-home-filter .form-home-filter-legend {
    width: 100%;
    display: block;
    margin-bottom: 30px;
    padding: 0;
    text-align: center;
    font-size: 20px;
  }

  .list-filter-info {
    margin-top: 30px;
    text-align: center;
  }

  .hero-slider-stage p {
    font-size: 30px;
    line-height: 34px;
  }

  .hero-slider-nav {
    display: none;
  }

  .banner-video:after {
    height: 100px;
  }

  .banner-video {
    height: 500px;
  }

  .card-collapse .card-collapse-title {
    font-size: 30px;
    line-height: 30px;
  }

  .card-collapse .card-collapse-description {
    font-size: 18px;
  }

  .card-link .card-link-box .card-link-box-content .card-link-box-content-text p {
    font-size: 16px;
  }

  .half-view .view {
    width: 100%;
    display: block;
    text-align: center;
  }

  .menu .menu-list .menu-list-item>a {
    padding: 10px 5px;
  }

  .filters .filters-container {
    padding: 25px 0;
  }

  .tayaya-mascot .tayaya-mascot-description:before {
    top: 30px;
    left: -200px;
    transform: scale(0.8);
  }

  .tayaya-mascot .tayaya-mascot-description .tayaya-mascot-title {
    font-size: 70px;
  }

  .tayaya-mascot .tayaya-mascot-description .tayaya-mascot-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 992px) {
  .form-float {
    width: 90%;
    right: 50%;
    transform: translate(50%, 0);
  }

  .form-float.open {
    transform: translate(50%, 15px);
  }

  .form-home-filter .input-group {
    margin-bottom: 15px;
  }

  .form-newsletter {
    padding: 40px 0;
  }

  .form-newsletter input {
    text-align: center;
  }

  .list-navigation>li.desktop-only {
    display: none;
  }

  .list-navigation>li.mobile-only-search {
    display: block;
  }

  .list-navigation {
    text-align: center;
    padding: 10px 0;
  }

  .hero-slider-stage {
    height: 340px;
  }

  .hero-slider-bullets {
    bottom: 30px;
  }

  .banner-video {
    height: 360px;
  }

  .card-collapse .card-collapse-description {
    height: 300px;
    overflow: auto;
    font-size: 16px;
  }

  .card-post .card-post-content {
    opacity: .8;
  }

  .card-table .card-table-column {
    display: block;
  }

  .newsletter .newsletter-header {
    padding: 30px 0;
    text-align: center;
  }

  .newsletter .newsletter-header:before {
    right: -250px;
  }

  .page-hero {
    height: 400px;
  }

  .page-hero .page-hero-title {
    font-size: 28px;
  }

  .page-header-custom .page-header-custom-title {
    font-size: 28px;
  }

  .jumbotron .jumbotron-title {
    font-size: 26px;
  }

  .jumbotron .jumbotron-description {
    font-size: 16px;
  }

  .quote-hero .quote-hero-text {
    font-size: 22px;
    line-height: 36px;
  }

  .half-view .view {
    font-size: 30px;
  }

  .col-fluid.col-fluid-media {
    width: 100%;
    height: 250px;
  }

  .col-fluid .fluid-content .fluid-content-action span {
    display: block;
    margin-top: 15px;
  }

  .carousel-hero .carousel-item {
    height: 500px;
  }

  .navigation .content-left {
    width: 100%;
    float: left;
    padding: 0;
    text-align: right;
  }

  .navigation .content-right {
    width: 100%;
    float: left;
    padding: 0 0 20px 0;
    text-align: center;
  }

  .logo {
    padding: 0 0 20px 0;
    text-align: center;
  }

  .menu .menu-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: none;
    background: #ff9800;
    z-index: 100;
  }

  .menu .menu-list .menu-list-item {
    transform: translateX(-5px);
  }

  .menu .menu-list .menu-list-item {
    display: block;
    text-align: center;
  }

  .footer .footer-credits .footer-credits-copyright {
    margin-bottom: 10px;
    text-align: center;
  }

  .footer .footer-credits .footer-credits-logo {
    margin-top: 10px;
    text-align: center;
  }

  .testimonials .testimonial-box {
    text-align: center;
  }

  .testimonials .testimonial-box .testimonial-box-image {
    padding: 25px 5px;
  }

  .instagram-feed .instagram-title a {
    font-size: 28px;
  }

  .tayaya-mascot .tayaya-mascot-inner {
    padding: 50px 0;
  }

  .tayaya-mascot .tayaya-mascot-description {
    padding: 30px;
    background: #fff;
    border: 4px solid #008b2f;
  }

  .tayaya-mascot .tayaya-mascot-description:before {
    display: none;
  }

  .tayaya-mascot .tayaya-mascot-image {
    margin-top: 50px;
    text-align: center;
  }

  .wd1200 {
    display: none;
  }

  .video-sec {
    height: 335px;
  }

  .video-sec iframe {
    height: 335px !important;
  }
}

@media screen and (max-width: 768px) {
  .modal .modal-header .modal-title {
    font-size: 22px;
  }

  .modal .modal-content {
    font-size: 16px;
  }

  .list-advantages li {
    width: 50%;
  }

  .list-map-place li {
    transform: scale(0.8);
    transform-origin: center center;
  }

  .hero-slider-stage {
    height: 300px;
  }

  .hero-slider-stage .content {
    transform: translateY(-75%);
  }

  .hero-slider-stage p {
    font-size: 22px;
    line-height: 24px;
  }

  .card-visual .card-visual-link {
    font-size: 20px;
  }

  .card-collapse {
    height: 500px;
  }

  .card-collapse .card-collapse-title {
    font-size: 22px;
    line-height: 22px;
  }

  .card-table .card-table-column .card-table-column-icons i {
    font-size: 45px;
  }

  .card-table .card-table-column .card-table-column-icons span {
    font-size: 22px;
    line-height: 24px;
  }

  .title-bubble {
    font-size: 28px;
  }

  .title-pointer {
    font-size: 20px;
  }

  .page-header .page-header-title {
    font-size: 28px;
    line-height: 28px;
  }

  .page-header-sm .page-header-sm-title {
    font-size: 32px;
  }

  .page-return {
    font-size: 18px;
  }

  .page-return .page-return-title {
    font-size: 28px;
  }

  .page-return .page-return-subtitle {
    font-size: 22px;
    line-height: 22px;
  }

  .quote-hero .quote-hero-text {
    font-size: 24px;
    line-height: 28px;
  }

  .half-view .view {
    padding: 20px;
    font-size: 20px;
  }

  .carousel-hero .carousel-item {
    height: 320px;
  }

  .carousel-hero .carousel-item .carousel-item-content {
    bottom: 70px;
    padding: 0 25px;
    font-size: 18px;
  }

  .accordion .accordion-title {
    font-size: 20px;
  }

  .accordion .accordion-content {
    font-size: 16px;
  }

  .instagram-feed .instagram-feed-container .instagram-feed-content {
    height: 200px;
  }

  .tayaya-mascot .tayaya-mascot-description .tayaya-mascot-title {
    font-size: 36px;
    line-height: 38px;
  }

  .tayaya-mascot .tayaya-mascot-description .tayaya-mascot-text {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) and (max-height: 380px) {
  .modal .modal-content {
    height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .list-advantages li {
    width: 100%;
  }

  .list-map-place li {
    transform: scale(0.6);
    transform-origin: center center;
  }

  .hero-slider-stage p {
    font-size: 18px;
    line-height: 24px;
  }

  .wd992 {
    display: none;
  }
}

@media screen and (max-width: 1400px) and (min-width: 992px) {
  .form-float {
    transform: translate(0, 0);
  }

  .form-float:after,
  .form-float:before {
    left: 80%;
  }

  .form-float.open {
    transform: translate(0, 15px);
  }
}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
  .instagram-feed .instagram-feed-container .instagram-feed-content {
    height: 230px;
  }
}